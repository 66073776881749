import React from 'react';
import { ThemeContext } from 'theme/ThemeContext';
import { Fade, Grid, Typography, useMediaQuery } from '@mui/material';
import useOnScreen from 'lib/hooks/useOnScreen';
import AnimatedDotsRow from './AnimatedDotsRow';
import { Box } from '@mui/system';
import MaybeShow from './MaybeShow';
import AnimatedDivider from './AnimatedDivider';

export default function Paragraph(props){
  const {
    title,
    subtitle,
    text,
    children,
    textAlign,
    hideDots,
    showDivider,
    fadeDelay,
  } = props
  
  const {theme} = React.useContext(ThemeContext)
  const ref = React.useRef()
  const isVisible = useOnScreen(ref, {oneTime: true})
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      ref={ref}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        paddingTop: 2,
        paddingBottom: 2,
        "& > *": {
          width: smallScreen ? "90%" : "75%",
          maxWidth: 900,
          textAlign: textAlign
        }
      }}
    >
      <MaybeShow show={title}>
        <Fade
          in={isVisible}
          timeout={1000 + fadeDelay}
        >
          <Typography 
            color="textPrimary" 
            variant={smallScreen ? "h4" : "h2"}
          >
            {title}
          </Typography>
        </Fade> 
      </MaybeShow>
      <MaybeShow show={subtitle}>
        <MaybeShow show={showDivider}>
          <Box
            sx={{
              marginTop: 2,
              marginBottom: 4,
              "& > *": {width: "100% !important"}
            }}
          >
            <AnimatedDivider
              startAnimation={isVisible}
              animationDuration="1.5s"
            />
          </Box>
        </MaybeShow>
        <Fade
          in={isVisible}
          timeout={1500 + fadeDelay}
        >
          <Typography 
            color="textPrimary" 
            variant={smallScreen ? "h6" : "h5"}
          >
            {subtitle}
          </Typography>
        </Fade> 
      </MaybeShow>
      <MaybeShow show={!hideDots}>
        <Box>
          <AnimatedDotsRow align={textAlign} in={isVisible}/>
        </Box>
      </MaybeShow>
      <MaybeShow show={text}>
        <Fade 
          in={isVisible}
          timeout={1500 + fadeDelay}
        >
          <Typography 
            color="textSecondary" 
            variant="subtitle1"
            component="p"
            sx={{paddingTop: 1}}
          >
            {text}
          </Typography>
        </Fade>
      </MaybeShow>
      {children}
    </Grid>
  )
}

Paragraph.defaultProps = {
  textAlign: "center",
  fadeDelay: 0
}