import { Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import ImageWithPaper from "components/ImageWithPaper";
import Paragraph from "components/Paragraph";
import React from "react";
import { ThemeContext } from "theme/ThemeContext";
import ContactBox from "./components/ContactBox";

export default function Contacts(){
  const {theme} = React.useContext(ThemeContext)
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Box minHeight="100vh">
        <ImageWithPaper
          offsetY="72px"
          invertDirectionOnMiddleScreen
          src="images/kaire2.webp" 
          alt="kaire outside"
          bottomLabel="Foto by Fiorenzo"
        >
          <Box pl={4} pr={4} pb={4}>
            <Paragraph
              title="I nostri contatti"
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={smallScreen ? 0 : 12}
              > 
                <Grid 
                  item 
                  sx={{textAlign: smallScreen ? "center" : "left"}}
                >
                  <Box mb={2}>
                    <Typography variant="h5" color="textSecondary">
                      Commerciale
                    </Typography>
                  </Box>
                  <ContactBox
                    name="Mauro Budriesi"
                    contact="budriesim@kaire-automation.it"
                    snackbarmessage="Email copiata"
                  />
                  <Box mt={2} mb={2}>
                    <Divider/>            
                  </Box>
                  <ContactBox
                    name="Massimo Bastoni"
                    contact="bastonim@kaire-automation.it"
                    snackbarmessage="Email copiata"
                  />
                </Grid>
                <Grid 
                  item 
                  sx={{textAlign: smallScreen ? "center" : "right"}}
                >
                  <Box mb={2}>
                    <Typography variant="h5" color="textSecondary">
                      Altri contatti
                    </Typography>
                  </Box>
                  <ContactBox
                    name="Telefono"
                    contact="+39 059 450736 "
                    snackbarmessage="Numero di telefono copiato"
                  />
                  <Box mt={2} mb={2}>
                    <Divider/>            
                  </Box>
                  <ContactBox
                    name="Email"
                    contact="info@kaire-automation.it"
                    snackbarmessage="Email copiata"
                  />
                </Grid>
              </Grid>
            </Paragraph>
          </Box>
        </ImageWithPaper>
        <iframe 
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2837.6317295042927!2d10.934598115733307!3d44.66587529437465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477feee0c94c3fc3%3A0xe1f34f67e366e2ad!2sKaire%20Automation!5e0!3m2!1sit!2sit!4v1638993158500!5m2!1sit!2sit" 
          width="100%" 
          height={smallScreen ? 300 : 400} 
          allowFullScreen 
          loading="lazy"
        />
      </Box>
    </>
  )
}