import React from 'react';
import { Box } from '@mui/system';

export default function SliderImageItem(props){

  return (
    <Box
      sx={{
        width: props.width,
        height: props.height,
        padding: 1,
        display: "flex",
        "& img": {
          maxWidth: props.width - 16,
          minHeight: props.height  - 16,
          objectFit: "contain",
          margin: "0 auto"
        }
      }}
    >
      <img src={props.src} alt={props.alt}/>
    </Box>
  )
}