import React from 'react';
import { Grid, IconButton, Tooltip, useMediaQuery } from '@mui/material';

import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { ThemeContext } from 'theme/ThemeContext';
import MaybeShow from 'components/MaybeShow';
import DialogBase from 'components/DialogBase';
import { Stack } from '@mui/system';

export default function Slider(props){
  const sliderRef = React.useRef()
  const bigImgRef = React.useRef()
  const currentIndex = React.useRef(0)
  const [openImg, setOpenImg] = React.useState(false)

  const {theme} = React.useContext(ThemeContext)
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const middleScreen = useMediaQuery(theme.breakpoints.down('md'));

  const {
    elementsShown,
    children,
    width,
    height,
  } = props


  const childrenWithProps = React.Children.map(children, child => {
    return React.cloneElement(child, {width, height})
  })

  const handleOpenImg = (img) => {
    setOpenImg(img)
  }

  const increaseScrollPosition = () => {
    currentIndex.current ++;

    if(currentIndex.current > childrenWithProps.length - getElementsShown())
      currentIndex.current = 0;

      updateImg()
  }

  const decreaseScrollPosition = () => {
    currentIndex.current --;

    if(currentIndex.current < 0)
      currentIndex.current = childrenWithProps.length - getElementsShown();

    updateImg()
  }

  const updateImg = () => {
    sliderRef.current.scrollLeft = currentIndex.current * width;
    
    if(!openImg)
      return

    bigImgRef.current.src = childrenWithProps[currentIndex.current]?.props?.src
    bigImgRef.current.alt = childrenWithProps[currentIndex.current]?.props?.alt
  }

  const getElementsShown = () => {
    if(elementsShown !== "auto")
      return elementsShown

    if(smallScreen)
      return 1

    if(middleScreen)
      return 2

    return 4
  }

  const onlyOneElement = elementsShown === 1
  const thereAreNotEnoughChildren = getElementsShown() >= childrenWithProps.length

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
    >
      <MaybeShow
        show={!thereAreNotEnoughChildren}
      >
        <Grid item>
          <IconButton 
            aria-label="Indietro"
            size="large"
            onClick={decreaseScrollPosition}
          >
            <ArrowBackIosRoundedIcon/>
          </IconButton>
        </Grid>
      </MaybeShow>
      <Grid
        ref={sliderRef}
        item
        container
        direction="row"
        sx={{
          width: width * getElementsShown(),
          overflow: "hidden",
          whiteSpace: "nowrap",
          display: "block",
          scrollBehavior: "smooth"
        }}
      >
        {
          childrenWithProps.map((child, index) => {
            return (
              <Tooltip
                key={index} 
                arrow
                title={`${onlyOneElement ? `Apri l'immagine -` : ''}${child.props.alt}`}  
              >
                <Grid 
                  item
                  onClick={() => onlyOneElement ? handleOpenImg(true) : undefined} 
                  sx={{
                    display: "inline-block",
                    "&: hover": {
                      cursor: onlyOneElement ? "pointer" : "cursor",
                    },
                  }}
                >
                  {child}
                </Grid>
              </Tooltip>
            )
          })
        }
        <DialogBase
          size="xl"
          open={openImg}
          onClose={_ => setOpenImg(false)}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <IconButton 
              aria-label="Indietro"
              size="large"
              onClick={decreaseScrollPosition}
            >
              <ArrowBackIosRoundedIcon/>
            </IconButton>
            <img
              ref={bigImgRef}
              src={childrenWithProps[currentIndex.current]?.props?.src} 
              alt={childrenWithProps[currentIndex.current]?.props?.alt}
              style={{ width: "calc(100% - 48px)" }}
            />
            <IconButton
              aria-label="Avanti"
              size="large"
              onClick={increaseScrollPosition}        
            >
              <ArrowForwardIosRoundedIcon/>
            </IconButton>
          </Stack>
        </DialogBase>
      </Grid>
      <MaybeShow
        show={!thereAreNotEnoughChildren}
      >
        <Grid item>
          <IconButton
            aria-label="Avanti"
            size="large"
            onClick={increaseScrollPosition}        
          >
            <ArrowForwardIosRoundedIcon/>
          </IconButton>
        </Grid>
      </MaybeShow>
    </Grid>
  )
}


Slider.defaultProps = {
  elementsShown: "auto"
}

