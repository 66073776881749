import React from "react";
import ButtonBase from '@mui/material/ButtonBase';
import PageController from "lib/PageController";

export default function Logo(props){
  return (
    <ButtonBase
      onClick={e => {
        PageController.push('/', e)
        props.onClick?.()
      }}
      sx={{
        height: props.height,
        padding: 1.5,
        "& > *": {
          height: "100%",
        }
      }}
    >
        <img 
          src="images/logo.webp" 
          alt="kaire-logo" 
        />
    </ButtonBase>
  )
}