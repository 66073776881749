import { useMediaQuery } from "@mui/material";
import Paragraph from "components/Paragraph";

import { ThemeContext } from "theme/ThemeContext";
import React from "react";
import Slider from "components/slider/Slider";
import SliderImageItem from "components/slider/SliderImageItem";

export default function Partners(){
  const {theme} = React.useContext(ThemeContext)
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  return (
    <Paragraph
      title="Alcuni dei nostri partner"
      hideDots
    >
      <Slider
        width={smallScreen ? 150 : 200}
        height={smallScreen ? 100 : 133}
      >
        <SliderImageItem alt="omron"      src="/images/partner/omron.webp"/>
        <SliderImageItem alt="siemens"       src="/images/partner/siemens.webp"/>
        <SliderImageItem alt="wonderware"  src="/images/partner/wonderware.webp"/>
      </Slider>
    </Paragraph>
  )
}