import React from 'react';
import { Button, Grow } from '@mui/material';
import PageController from 'lib/PageController';

export default function GrowingButton(props){
  return (
    <Grow 
      in 
      timeout={props.timeout}
      {...props.GrowProps}
    >
      <Button
        color="secondary"
        onClick={e => {
          props.onClick?.(e)
          
          if(props.href)
            PageController.push(props.href, e)
        }}
        href={props.href}
        {...props.ButtonProps}
      >
        {props.children}
      </Button>
    </Grow>
  )
}