
import React from "react";

import { IconButton } from "@mui/material";
import { ThemeContext } from "../theme/ThemeContext";
import ThemeIcon from "./ThemeIcon";

export default function ThemeModeButton(props){
  const {toggleThemeMode} = React.useContext(ThemeContext);

  return (
    <IconButton 
      aria-label="Cambia tema"
      {...props.IconButtonProps}
      onClick={toggleThemeMode}
    >
      <ThemeIcon {...props.IconProps}/>
    </IconButton>
  )
}