import { Card, CardActionArea, CardContent, Tooltip } from "@mui/material";
import Bold from "components/Bold";
import DialogBase from "components/DialogBase";
import Paragraph from "components/Paragraph";
import React from "react";

export default function ProjectCard(props){
  const [openDialog, setOpenDialog] = React.useState(false);

  return (
    <>
    <Tooltip
      title="Premi per scoprire di più"
      arrow
    >
      <Card sx={{height: "100%"}}>
        <CardActionArea 
          sx={{height: "100%"}}
          onClick={_ => setOpenDialog(true)}
          aria-label={props.title}
        >
          <CardContent>
            <Paragraph
              hideDots
              fadeDelay={props.fadeDelay}
              subtitle={<Bold>{props.title}</Bold>}
              text={props.text}
            />  
          </CardContent>
        </CardActionArea>
      </Card>
    </Tooltip>
    <DialogBase
      title={props.title}
      open={openDialog}
      onClose={_ => setOpenDialog(false)}
    >
      {props.dialogContent}
    </DialogBase>
    </>
  )
}