import React from 'react';
import { styled, keyframes } from "@mui/system";
import { Typography } from '@mui/material';

const animate = keyframes`
  0% { 
    stroke-dasharray: 0 50%; 
    stroke-dashoffset: 0%;
    fill-opacity: 0%;
  }
  80% {fill-opacity: 0%;}
  99% {fill-opacity: 100%;}
  100% { 
    stroke-dasharray: 50% 0; 
    stroke-dashoffset: 20%;
  }
`
const StyledText = styled("text")({});

export default function AnimatedText(props){
  const {
    TypographyProps,
    viewBox,
    variant,
    children, 
    fillColor, 
    strokeColor,
    strokeWidth, 
    animationDuration
  } = props

  return (
    <Typography 
      {...TypographyProps}
      variant={variant}
    >
      <svg viewBox={viewBox}>
        <StyledText 
          x="50%" 
          y="50%" 
          textAnchor="middle"
          sx={{
            animation: `${animate} ${animationDuration} ease-in-out`,
            fill: fillColor,
            stroke: strokeColor,
            strokeWidth: strokeWidth  
          }}
        >
          {children}
        </StyledText>
      </svg>
    </Typography>
  )
}