import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Paragraph from "components/Paragraph";

import CropSquareRoundedIcon from '@mui/icons-material/CropSquareRounded';
import GridOnRoundedIcon from '@mui/icons-material/GridOnRounded';
import BuildRoundedIcon from '@mui/icons-material/BuildRounded';

import Bold from "components/Bold";
import React from "react";
import AnimatedDotsRow from "components/AnimatedDotsRow";
import ServicesListItem from "../components/ServicesListItem";

export default function Hardware(){
  return (
    <Paper
      sx={{backgroundColor: "rgba(0,0,0,0.15) !important"}}
    >
      <Paragraph
        subtitle="Hardware"
        hideDots
        text={
          <>
            <Bold>Kaire</Bold> è in grado di fornire ai propri cliente un impianto chiavi in mano oltre alla progettazione software offriamo una completa Progettazione Hardware.
          </>
        }
      >
        <Box
          mt={2}
          mb={2}
        >
          <AnimatedDotsRow in/>
        </Box>
        <Typography
          variant="h6"
          color="secondary"
        >
          I nostri servizi comprendono
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="center"
        >
          <ServicesListItem
            icon={<CropSquareRoundedIcon/>}
            text="Progettazione di Schemi elettrici utilizzando SPAC e EPLAN"
          />
          <ServicesListItem
            icon={<GridOnRoundedIcon/>}
            text="Realizzazione ed installazione di Quadri Elettrici"
          />
          <ServicesListItem
            icon={<BuildRoundedIcon/>}
            text="Impiantistica generale e bordo macchina"
          />
        </Grid>
      </Paragraph>
    </Paper>
  )
}