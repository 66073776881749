import { createTheme } from '@mui/material/styles';

const getThemeMode = (themeMode) => {
  const theme = createTheme({
    palette: {
      mode: themeMode,
      background: {
        paper: themeMode === 'light'
          ? '#eee'
          : '#333'
      },
      primary: {
        main: "#9BC78F",
      },
      secondary: {
        main: "#A3AF9F",
      },
    },
    typography: {      
      fontFamily: 'Poppins'
    },
    zIndex: {
      appBar: 1250
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {

            '&::-webkit-scrollbar': {
              width: '4px',
              height: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: "#A3AF9F",
              borderRadius: '2px',
            },
          }
        },
      },
    },
  });

  document.body.style.background = theme.palette.background.default
  return theme;
}

export default getThemeMode