import { Button, IconButton, useMediaQuery } from "@mui/material";
import React from "react";
import CustomDrawer from "./drawer/CustomDrawer";

import { Box } from "@mui/system";
import CustomAppBar from "./CustomAppBar";

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

import DrawerListItem from "./drawer/DrawerListItem";
import Footer from "./Footer";
import { ThemeContext } from "theme/ThemeContext";
import MaybeShow from "./MaybeShow";
import PageController from "lib/PageController";
import ThemeModeButton from "./ThemeModeButton";

export default function Layout({children}){
  const {theme} = React.useContext(ThemeContext)
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [openDrawer, setOpenDrawer] = React.useState(false)

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer)
  }

  return (
    <>
      <CustomAppBar
        onDrawerClose={_ => setOpenDrawer(false)}
      >
        <MaybeShow show={!smallScreen}>
          <Box p="6px" mr={1}>
            <Button
              variant="outlined"
              color="primary"
              href="/contattaci"
              onClick={e => PageController.push("/contattaci", e)}
            >
              Contattaci
            </Button>
          </Box>
        </MaybeShow>
        <ThemeModeButton IconButtonProps={{ size: 'large' }} />
        <IconButton
          aria-label="Apri sidebar"
          size="large"
          onClick={toggleDrawer}
        >
          {
            openDrawer
              ? <CloseRoundedIcon/>
              : <MenuRoundedIcon/>
          }
        </IconButton>
      </CustomAppBar>
      <CustomDrawer
        open={openDrawer}
        onClose={_ => setOpenDrawer(false)}
      >
        <DrawerListItem
          text="Chi siamo?"
          href="/chi_siamo"
          onClick={_ => setOpenDrawer(false)}
        />
        <DrawerListItem
          text="Servizi"
          href="/servizi"
          onClick={_ => setOpenDrawer(false)}
        />
        <DrawerListItem
          text="Progetti"
          href="/progetti"
          onClick={_ => setOpenDrawer(false)}
        />
        <DrawerListItem
          text="K-Trend"
          href="/k-trend"
          onClick={_ => setOpenDrawer(false)}
        />
        <MaybeShow
          show={smallScreen}
        >
          <DrawerListItem
            text="Contattaci"
            href="/contattaci"
            onClick={_ => setOpenDrawer(false)}
          />
        </MaybeShow>
      </CustomDrawer>
      {children}
      <Footer/>
    </>
  )
}