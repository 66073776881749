import { AccessTime, CompressRounded, DevicesRounded, EscalatorRounded, ExpandMoreRounded, HelpRounded, LightbulbRounded, RedoRounded, SecurityRounded, ShieldRounded } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Link, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Paragraph from "components/Paragraph";
import Slider from "components/slider/Slider";
import SliderImageItem from "components/slider/SliderImageItem";
import PageController from "lib/PageController";
import ServicesListItem from "pages/Services/components/ServicesListItem";

export default function KTrend() {
  const theme = useTheme()
  const is350 = useMediaQuery(theme.breakpoints.down(350));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const isLg = useMediaQuery(theme.breakpoints.down('lg'));

  const getSliderWiidth = () => {
    if(is350)
      return 150

    if(isSm)
      return 200

    if(isMd)
      return 400

    if(isLg)
      return 500

    return 800
  }

  return (
    <Box
      mt={12}
      minHeight="100vh"
    >
      <Paragraph
        title="K-Trend"
        text={<>
        <strong>K-Trend</strong> è un'applicazione che consente di storizzare e visualizzare i dati provenienti dalle macchine e dagli impianti di processo nel corso del tempo. 
        <br/>
        Grazie alla sua ampia gamma di funzionalità, K-Trend si presta a diverse applicazioni, tra cui la raccolta dei dati per ottenere statistiche di funzionamento negli impianti di produzione e nelle linee automatizzate.
        <br/>
        <br/>
        L'obiettivo principale di <strong>K-Trend</strong> è quello di agevolare la visualizzazione e l'analisi dell'andamento dei dati nel tempo, oltre a consentire la visualizzazione di statistiche dettagliate sui dati stessi.   
        </>}
      >
        <Box
          sx={{
            position: 'relative',
            marginTop: 8,
            width: '100%',
            "& > *": {
              width: '100%'
            }
          }}
        >
          <Slider
            elementsShown={1}
            width={getSliderWiidth()}
            height={getSliderWiidth() / 2}
          >
            <SliderImageItem alt="K-Trend 1"  src="/images/k-trend/ktrend1.png"/>
            <SliderImageItem alt="K-Trend 2"  src="/images/k-trend/ktrend2.png"/>
            <SliderImageItem alt="K-Trend 3"  src="/images/k-trend/ktrend3.png"/>
            <SliderImageItem alt="K-Trend 4"  src="/images/k-trend/ktrend4.png"/>
          </Slider>
        </Box>
      </Paragraph>

      <Stack
        component={Paper}
        spacing={8}
        sx={{ 
          padding: 4,
          marginTop: 8,
          maxWidth: 1000,
          marginBlock: 10,
          marginInline: { xs: 2, md: 'auto'}
        }}
      >
          <Stack
            direction="column"
            spacing={4}
          >
            <Typography
              variant={isMd ? "h5" : "h4"}
              textAlign="center"
            >
              Caratteristiche
            </Typography>
            <Stack
              direction={{ xs: "column", md: "row"}}
              alignItems="flex-start"
              justifyContent="flex-start"
              spacing={2}
            >
              <ServicesListItem
                icon={<EscalatorRounded/>}
                text={
                  <>
                    <strong>SCALABILITÀ ORIZZONTALE</strong>
                    <br/>
                    Installazione distribuita
                  </>
                }
              />
              <ServicesListItem
                icon={<CompressRounded/>}
                text={
                  <>
                    <strong>Compressione</strong>
                    <br/>
                    Ottima compressione dei dati, sistema binario basato su MongoDB
                  </>
                }
              />
              <ServicesListItem
                icon={<AccessTime/>}
                text={
                  <>
                    <strong>VISUALIZZAZIONE IN TEMPO REALE</strong>
                    <br/>
                    Si possono vedere i dati Real time in aggiornamento continuo
                  </>
                }
              />
            </Stack>
            <Stack
              direction={{ xs: "column", md: "row"}}
              alignItems="flex-start"
              justifyContent="flex-start"
              spacing={2}
            >
              <ServicesListItem
                icon={<DevicesRounded/>}
                text={
                  <>
                    <strong>ACCESSIBILITÀ</strong>
                    <br/>
                    Tecnologia multipiattaforma: su differenti device (PC , smartphone, Tablet, MAC IOS, Windows, Linux)
                  </>
                }
              />
              <ServicesListItem
                icon={<LightbulbRounded/>}
                text={
                  <>
                    <strong>BUSINESS INTELLIGENCE</strong>
                    <br/>
                    Raccolta dati eterogenei fra impianti
                  </>
                }
              />
                            <ServicesListItem
                icon={<RedoRounded/>}
                text={
                  <>
                    <strong>RIPETIBILITÀ</strong>
                    <br/>
                    Non è più necessario essere nella rete del PLC per registrare i dati
                  </>
                }
              />
            </Stack>
            <Stack
              direction={{ xs: "column", md: "row"}}
              alignItems="flex-start"
              justifyContent="flex-start"
              spacing={2}
            >
              <ServicesListItem
                icon={<ShieldRounded/>}
                text={
                  <>
                    <strong>SICUREZZA DEI DATI</strong>
                    <br/>
                    Backup a caldo dei dati HOT BACKUP con “Data sharding”
                  </>
                }
              />
              <ServicesListItem
                icon={<SecurityRounded/>}
                text={
                  <>
                    <strong>CYBER SECURITY</strong>
                    <br/>
                    Accesso in https con credenziali criptate

                    Protocollo di autenticazione OAuth2
                  </>
                }
              />
              <Box sx={{width: '100%'}}></Box>
            </Stack>
          </Stack>
        </Stack>
        
        <Box
          sx={{
            marginTop: 8,
            padding: 6,
            width: '100%',
            background: 'rgba(0, 0, 0, 0.1)'
          }}
        >
          <Stack
            direction="column"
            sx={{ maxWidth: 1400, marginInline: 'auto' }}
          >
            <Stack
              spacing={4}
              direction={{ xs: "column", md: "row"}}
              alignItems="flex-start"
            >
              <Stack
                spacing={{ xs: 2, md: 4 }}
                direction="row"
                alignItems="center"
              >
                <Avatar
                  sx={{
                    width: { xs: 60, md: 100 }, 
                    height: { xs: 60, md: 100 }
                  }}
                >
                  <HelpRounded 
                    sx={{ 
                      transform: { xs: 'scale(1.5)', md: 'scale(2.5)'} 
                    }}
                  />
                </Avatar>
                <Typography
                  variant={isMd ? "h4" : "h3"}
                >
                  Domande frequenti
                </Typography>
              </Stack>


              <Stack
                direction="column"
                sx={{ width: '100%' }}
              > 
                <Question title="Come funzionano le licenze?" text={
                  <>
                    Il software viene licenziato in base al numero di utenti e al numero di grandezze campionate.
                    <br/>
                    <br/>
                    Esistono diversi tagli&nbsp;
                    <Link
                      href="/contattaci"
                      onClick={e => PageController.push("/contattaci", e)}
                    >
                      contattare il nostro ufficio commerciale
                    </Link>
                    .
                  </>
                }/>
                <Question title="Esiste una versione di prova?" text={
                  <>
                    Si esiste,&nbsp;
                    <Link
                      href="/contattaci"
                      onClick={e => PageController.push("/contattaci", e)}
                    >
                      contattare il nostro ufficio commerciale
                    </Link>
                  </>
                }/>
                <Question title="Quali sono i requisiti?" text={
                  <>
                    <strong>Requisiti software</strong>
                    <ul>
                      <li>MongoDB 5.0 o successivo</li>
                      <li>ASP.NET Core Hosting Bundle con net6.0</li>
                      <li>IIS 10 o successivo</li>
                      <li>Modulo UrlRewrite per IIS</li>
                      <li>SQL Server</li>
                    </ul>
                    <br/>
                    <br/>
                    <strong>Requisiti hardware</strong>
                    <ul>
                      <li>Windows 10, Windows Server 2016, Windows Server 2019</li>
                      <li>Processore i5/I7 o superiore</li>
                      <li>12Gb RAM minimi, 16Gb consigliati</li>
                      <li>500Gb SDD minimo Consigliato 1 TB
                        <ul>
                          <li>
                          350 Mb / variabile / anno senza ottimizzazioni (deadband)
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </>
                }/>
              </Stack>
            </Stack>
          </Stack>
        </Box>
    </Box>
  )
}

function Question(props) {
  return (
    <Accordion sx={{ padding: 1.5 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreRounded />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6">{props.title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="span">
          {props.text}
        </Typography>
      </AccordionDetails>
    </Accordion>
  )
}