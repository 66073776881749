import React from "react";
import { AppBar, Grid, Slide, Toolbar, useScrollTrigger } from "@mui/material";
import Logo from "./Logo";

export default function CustomAppBar(props){
  return (
    <HideOnScroll>
      <AppBar
        sx={{
          background: theme => theme.palette.background.paper
        }}
      >
        <Toolbar>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Logo 
                height={72}
                onClick={props.onDrawerClose}
              />
            </Grid>
            <Grid 
              item
              sx={{display: "flex"}}
            >
              {props.children}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  )
}

function HideOnScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}