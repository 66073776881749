
import React from "react";

import { ThemeContext } from "../theme/ThemeContext";

import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';

export default function ThemeIcon(props){
  const {themeMode} = React.useContext(ThemeContext);

  return (
    themeMode === "light"
      ? <DarkModeRoundedIcon {...props}/>
      : <LightModeRoundedIcon {...props}/>
  )
}
