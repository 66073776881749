import React from 'react';
import { Grid } from '@mui/material';
import GrowingDot from './GrowingDot';

export default function AnimatedDotsRow(props) {
  if(!props.in)
    return null;

  return (
    <Grid
      container
      direction="row"
      justifyContent={props.align}
      alignItems="center"
    >
      <GrowingDot in={props.in} timeout={1000}/>
      <GrowingDot in={props.in} timeout={2000}/>
      <GrowingDot in={props.in} timeout={3000}/>
    </Grid>
  )
}

AnimatedDotsRow.defaultProps = {
  align: "center"
}