import Bold from "components/Bold";
import Paragraph from "components/Paragraph";
import React from "react";

export default function WhoWeAreMainParagraph(){
  return (  
    <Paragraph
      title="La nostra storia"
      textAlign="left"
      text={
        <>
          <span>
            <Bold>Kaire</Bold> nasce nel 2015 dalla volontà e dalle competenze professionali di un gruppo di tecnici con esperienza ventennale nell’ambito delle <Bold>automazioni e dei controlli di processo industriali</Bold>.
            <br/>
            <br/>
          </span>
          <span>
            Realizziamo soluzioni integrate per il controllo e la <Bold>supervisione</Bold> degli impianti industriali, piattaforme software per la gestione e la pianificazione della produzione, sistemi per la <Bold>tracciabilità e analisi</Bold> dei dati produttivi (KPI OEE ,SPC).
            <br/>
            <br/>
          </span>
          <span>
            Le competenze acquisite, la determinazione alla crescita professionale e al raggiungimento degli obbiettivi, hanno reso possibile la creazione di una <Bold>flessibile e coordinata struttura produttiva</Bold>, che ci ha permesso di affrontare adeguatamente ogni sfida riguardante le automazioni , il processo e la gestione della produzione.              </span>
            <br/>
            <br/>
          <span>
            E’ lavorando fianco a fianco con i nostri clienti che siamo risusciti a consolidare molti e importanti rapporti di collaborazione raccogliendo significativi consensi di fiducia.
          </span>
        </>
      }    
    />
  )
}