import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material";

export default function ServicesListItem(props){
  return (
    <ListItem
      sx={{
        maxWidth: 400,
        width: "100%",
        alignItems: "flex-start"
      }}
    >
      <ListItemAvatar>
        <Avatar>
          {props.icon}
        </Avatar>
      </ListItemAvatar>
      <ListItemText secondary={props.text}/>
    </ListItem>
  )
}