import React from "react";
import { ThemeContext } from "theme/ThemeContext";

export default function Bold({children}){
  const {theme} = React.useContext(ThemeContext)

  return (
    <strong 
      style={{
        color: theme.palette.secondary.dark,
      }}
    >
      {children}
    </strong>
  )
}