import { Box } from "@mui/system";
import AnimatedDivider from "components/AnimatedDivider";
import Paragraph from "components/Paragraph";
import React from "react";
import { ThemeContext } from "theme/ThemeContext";


import TheOrganization from "./components/TheOrganization";
import WhoWeAreMainParagraph from "./components/WhoWeAreMainParagraph";
import Bold from "components/Bold";
import ImageWithPaper from "components/ImageWithPaper";

export default function WhoWeAre(){
  const {theme} = React.useContext(ThemeContext)

  return (
    <Box minHeight="100vh">
      <ImageWithPaper
        src="images/kaire1.webp" 
        alt="kaire outside"
        bottomLabel="Foto by Fiorenzo"
        offsetY="72px"
      >
        <WhoWeAreMainParagraph/>
      </ImageWithPaper>
      <Box   
        sx={{background: theme.palette.background.default}}
        mb={4}
      >
        <TheOrganization/>
        <AnimatedDivider
          animationDuration="3s"
        />
        <Box mt={4}>
          <Paragraph
            hideDots
            title="Il team"
            textAlign="start"
            text={
              <>
                Il nostro <Bold>team</Bold> è tendenzialmente molto <Bold>giovane</Bold>, questo perché nei giovani troviamo la <Bold>curiosità e l’entusiasmo nel creare</Bold>, realizzare e veder crescere i nostri progetti. Ovviamente l’esperienza è importante, ed è per questo che i giovani sono guidati, seguiti passo dopo passo dai soci e dai colleghi di maggior esperienza, comunque liberi di esprime il proprio potenziale.
                <br/>
                <br/>
                Il <Bold>reparto commerciale è in primis anche gruppo di tecnici</Bold> di lunga esperienza: questo aiuta notevolmente il cliente ad effettuare scelte progettuali che portino agli obbiettivi richiesti senza trascurare l’aspetto economico.
              </>
            }
          />
        </Box>
      </Box>
    </Box>
  )
}
