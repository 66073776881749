import { Paper, Stack, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { ThemeContext } from "theme/ThemeContext";
import MaybeShow from "./MaybeShow";
import Parallax from "./Parallax";

export default function ImageWithPaper(props){
  const {theme} = React.useContext(ThemeContext)
  const middleScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <MaybeShow
      show={middleScreen}
      alternativeChildren={
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            position: 'relative',
            marginTop: props.offsetY,
            minHeight: `calc(98vh - ${props.offsetY})`,
            backgroundImage: `url(${props.src})`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
          }}
        >
          <Paper
            sx={{
              margin: 4,
              padding: 2,
              left: "50%",
              top: "50%",
              maxWidth: 1200,
              zIndex: 1
            }}
          >
            {props.children}
          </Paper>
          {
            props.bottomLabel &&
              <Typography 
              sx={{
                position: "absolute",
                bottom: "10px",
                left: "20px",
                maxWidth: "200px",
                height: "20px",
              }}
            >
              {props.bottomLabel}
            </Typography>
          }
        </Stack>
      }
    >
      {
        !props.invertDirectionOnMiddleScreen &&
          <Box pt={8}>
            {props.children}
          </Box>
      }
      <Box sx={{position: "relative"}}>
        {
          props.bottomLabel &&
            <Typography 
            sx={{
              position: "absolute",
              left: "20px",
              maxWidth: "200px",
              height: "20px",
              bottom: "10px"
            }}
          >
            {props.bottomLabel}
          </Typography>
        }
        <Parallax>
          <img src={props.src} alt={props.alt}/>
        </Parallax> 
      </Box>
      {
        props.invertDirectionOnMiddleScreen &&
          <Box pt={8}>
            {props.children}
          </Box>
      }      
    </MaybeShow>
  )
}

ImageWithPaper.defaultProps = {
  offsetY: '0px' 
}