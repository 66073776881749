import React from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

export default function TypographyWithIcon(props){
  const {
    icon,
    children,
    gridItem,
    ...TypographyProps
  } = props;

  const defaultTypography = 
    <Typography 
      {...TypographyProps}
    >
      {children}
    </Typography>

  return (
    <Grid
      item={gridItem}
      container
      direction="row"
      alignItems="center"
    >
      {icon}
      <Box ml={2}>
        {
          props.TypograghyAlternative 
            ? props.TypograghyAlternative
            : defaultTypography
        }
      </Box>
    </Grid>
  )    
}