import React from 'react';
import { ThemeContext } from 'theme/ThemeContext';
import { Button, Grid, useMediaQuery } from '@mui/material';
import AnimatedText from 'components/AnimatedText';
import { Box } from '@mui/system';
import AnimatedDivider from 'components/AnimatedDivider';
import GrowingButton from 'components/GrowingButton';
import VideoContainer from 'components/VideoContainer';
import Paragraph from 'components/Paragraph';
import PageController from 'lib/PageController';
import Slider from 'components/slider/Slider';
import SliderImageItem from 'components/slider/SliderImageItem';
import Parallax from 'components/Parallax';
import Bold from 'components/Bold';


export default function Home(){
  const {theme} = React.useContext(ThemeContext)

  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const randomVideo = Math.random() > 0.5 
    ? "video1.webm"
    : "video2.webm"
    
  return (
    <>    
      <VideoContainer
        video={`videos/${randomVideo}`}
        height="calc(100vh - 40px)"
      >
        <AnimatedText
          variant="h1"
          viewBox="0 0 1000 250"
          strokeWidth={2}
          strokeColor="#ddd"
          fillColor="#ddd"
          animationDuration="3s"
          TypographyProps={{
            sx: {
              width: "100%",
              maxWidth: "900px",
            }
          }}
        >
          <tspan x="50%" dy="0">La tranquillità</tspan>
          <tspan x="50%" dy="110px">del risultato</tspan>
        </AnimatedText>
        <AnimatedDivider
          animationDuration="3s"
        />
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid item>
            <GrowingButton 
              timeout={2000}
              href="/chi_siamo"
            >
              Chi siamo?
            </GrowingButton>
          </Grid>
          <Grid item>
            <GrowingButton 
              timeout={3000}
              href="/servizi"
            >
              Servizi
            </GrowingButton>
          </Grid>          
          <Grid item>
            <GrowingButton 
              timeout={4000}
              href="/progetti"
            >
              Progetti
            </GrowingButton>
          </Grid>
        </Grid>
      </VideoContainer>    
      <Paragraph
        title="Una breve introduzione"
        text={
          <>
            <Bold>Kaire</Bold> nasce nel 2015 dalla volontà e dalle competenze professionali di un gruppo di tecnici con esperienza ventennale nell’ambito delle automazioni e dei controlli di processo industriali.
          </>
        }
      >
        <Box mt={2} mb={2}>
          <Button 
            variant="outlined"
            onClick={e => PageController.push('/chi_siamo', e)}
          >
            Scopri di più
          </Button>
        </Box>
      </Paragraph>
      <Parallax 
        directions={{
          "top": { excursion: "auto" },
        }}
      >
        <img 
          src="/images/cable_wb.webp" 
          alt="kaire metal"
          style={{width: "100vw", objectFit: "cover"}}
        />
      </Parallax>
      <Paragraph
        title="I nostri principali clienti"
        hideDots
      >
        <Slider
          width={smallScreen ? 150 : 200}
          height={smallScreen ? 100 : 133}
        >
          <SliderImageItem alt="barilla"      src="/images/clients/barilla.webp"/>
          <SliderImageItem alt="DELIZIA"      src="/images/clients/DELIZIA.webp"/>
          <SliderImageItem alt="FABBRI"       src="/images/clients/FABBRI.webp"/>
          <SliderImageItem alt="farpro"       src="/images/clients/farpro.webp"/>
          <SliderImageItem alt="G7"           src="/images/clients/G7.webp"/>
          <SliderImageItem alt="granarolo"    src="/images/clients/granarolo.webp"/>
          <SliderImageItem alt="lattecarso"   src="/images/clients/lattecarso.webp"/>
          <SliderImageItem alt="newlat"       src="/images/clients/newlat-logo.webp"/>
          <SliderImageItem alt="sterilgarda"  src="/images/clients/sterilgarda.webp"/>
        </Slider>
      </Paragraph>
    </>
  )
}