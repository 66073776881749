import { Accordion, AccordionDetails, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Paragraph from "components/Paragraph";
import React from "react";

import EngineeringRoundedIcon from '@mui/icons-material/EngineeringRounded';
import DataSaverOffRoundedIcon from '@mui/icons-material/DataSaverOffRounded';
import AccordionSummaryWithIcon from "components/AccordionSummaryWithIcon";
import Bold from "components/Bold";

export default function TheOrganization(){
  return (
    <Paragraph
      hideDots
      title="L’organizzazione"
      textAlign="center"
      text="L’organizzazione di Kaire si basa su due attività produttive."
    >
      <Box mt={2}>
        <Accordion>
          <AccordionSummaryWithIcon
            icon={<EngineeringRoundedIcon/>}
          >
            ENGINEERING
          </AccordionSummaryWithIcon>
          <AccordionDetails>
            <Typography>
              La divisione <Bold>ENGINEERING</Bold> si occupa dello sviluppo delle specifiche applicazioni di ingegneria dei sistemi , principali settori di riferimento Handling e Magazzini automatici , impianti di processo.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummaryWithIcon
            icon={<DataSaverOffRoundedIcon/>}
          >
            MES
          </AccordionSummaryWithIcon>
          <AccordionDetails>
            <Typography>
              La divisione <Bold>MES</Bold> si occupa dell’analisi e dello sviluppo dei dati provenienti dai sistemi Produttivi , attraverso piattaforme proprie e standard.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Paragraph>
  )
}