import { Button, Card, CardActionArea, CardActions, Grid, List, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import AnimatedDivider from "components/AnimatedDivider";
import AnimatedDotsRow from "components/AnimatedDotsRow";
import Bold from "components/Bold";
import CompactListItem from "components/CompactListItem";
import Paragraph from "components/Paragraph";
import Slider from "components/slider/Slider";
import SliderImageItem from "components/slider/SliderImageItem";
import PageController from "lib/PageController";
import React from "react";
import { ThemeContext } from "theme/ThemeContext";
import ProjectCard from "./components/ProjectCard";

export default function Projects(){
  const {theme} = React.useContext(ThemeContext)
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      mr="auto"
      ml="auto"
      mb={4}
      mt={smallScreen ? 12 : 0}
      maxWidth={1200}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          minHeight: "90vh",
          paddingInline: 2,
          boxSizing: 'border-box'
        }}
      >
        <Grid
          container
          direction="row"
          spacing={smallScreen ? 2 : 6} 
        >
          <Grid item xs={12} sx={{textAlign: "center"}}>
            <Typography variant={smallScreen ? "h4" : "h3"}>
              Alcuni nostri progetti
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <AnimatedDivider/>
          </Grid>
          <Grid item xs={12} md={6}>
            <ProjectCard
              title="Tracesafety"
              text="Suite di applicazioni per la gestione della tracciabilità del settore alimentare."
              dialogContent={
                <>
                  <Typography>
                    <Bold>Suite di applicazioni per la gestione della tracciabilità del settore alimentare.</Bold>
                    <br/>
                    Unica fonte di dati per tutto lo stabilimento, si integra perfettamente con i sistemi d’automazione di fabbrica (SCADA/PLC).
                  </Typography>  
                  <Box mt={4} mb={1}>
                    <AnimatedDivider in maxWidth="100%" width="100%"/>
                  </Box>              
                  <List>
                    <CompactListItem
                      primary={<Bold>Infrastruttura modulare</Bold>}
                    />
                    <CompactListItem
                      primary={<>Modulo di gestione registrazione  <Bold>Ingresso/Uscita Camion dallo stabilimento</Bold></>}
                    />
                    <CompactListItem
                      primary={<>Modulo di gestione <Bold>analisi di laboratorio</Bold></>}
                    />
                    <CompactListItem
                      primary={<>Modulo di gestione <Bold>controllo peso confezioni a campione</Bold></>}
                    />
                    <CompactListItem
                      primary={<>Modulo di gestione <Bold>rotoli di carta e Tetra Brik tramite terminali in RF</Bold></>}
                    />
                    <CompactListItem
                      primary={<>Modulo di gestione <Bold>pallettizzatore e stampa etichetta pallet</Bold></>}
                    />
                    <CompactListItem
                      primary={<>Modulo di gestione <Bold>comunicazione con Host aziendale</Bold></>}
                    />
                    <CompactListItem
                      primary={<>Modulo di gestione <Bold>distinte base di prodotto</Bold></>}
                    />
                  </List>
                </>
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ProjectCard
              title="Scada Mes"
              text="Realizzazione dell’apparato di supervisione e campionamento dati per le aziende."
              dialogContent={
                <>
                  <Typography>
                    Un importante progetto di Kaire è la realizzazione dell’apparato di supervisione e campionamento dati per l’azienda Sofidel (produzione e trasformazione carta).
                    <br/>
                    <br/>
                    Per la realizzazione del progetto, è stato organizzato un
                    <Bold> sistema costruito su database Oracle e gestito da un’interfaccia implementata in ambiente C#.</Bold>
                  </Typography> 
                  <Box mt={2} mb={2}>
                    <AnimatedDotsRow in/>
                  </Box>
                  <Typography textAlign="center">
                    Il software fornito risiede in quasi tutte le filiali <Bold>Sofidel del mondo</Bold>.
                  </Typography>
                  <List 
                    sx={{
                      "& span": {textAlign: "center"}, 
                      "& > li": {marginTop: 2}
                    }}
                  >
                    <CompactListItem
                      primary={<>Kaire inoltre offre a Sofidel un’intera gamma di <Bold>applicativi in costante aggiornamento</Bold> utili alle gestioni dei vari dati negli stabilimenti e al monitoraggio dell’andamento macchine.</>}
                    />
                    <CompactListItem
                      primary={<>Abbiamo modo di interfacciarci con i vari fornitori di Sofidel attraverso <Bold>collaudi presso le rispettive sedi</Bold> ai fini di testare il corretto interfacciamento fra macchine in produzione e sistema di supervisione.</>}
                    />
                    <CompactListItem
                      primary={<>Stiamo tutto ora seguendo la continua espansione di Sofidel che richiede un continuo rinnovamento per <Bold>mantenere aggiornato il monitoraggio dati delle macchine negli stabilimenti sempre più numerose.</Bold></>}
                    />
                  </List>
                </>
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ProjectCard
              title="Ksync"
              text="Gestione anagrafiche su interfaccia web."
              dialogContent={
                <>
                  <Typography>
                    Prodotti sviluppato per coadiuvare il cliente nella gestione del proprio processo produttivo e gestionale. Anagrafiche materie prime, ricettazione, anagrafica prodotto.
                    <br/>
                    <br/>
                    <Bold>Sviluppato in Angular </Bold>
                    con implementazione di <Bold>Bootstrap</Bold>, che si appoggia a <Bold>Web-Api RESTful sviluppate in .NET Core</Bold>.
                  </Typography> 
                  <Box mt={4} mb={1}>
                    <AnimatedDivider in maxWidth="100%" width="100%"/>
                  </Box>
                  <List>
                    <CompactListItem
                      primary={<>Gestione <Bold>anagrafiche WEB</Bold></>}
                    />
                    <CompactListItem
                      primary={<>Gestione etichettatrici tramite <Bold>WEB API</Bold></>}
                    />
                    <CompactListItem
                      primary={<>Interfacciamento con <Bold>sistemi di fabbrica</Bold></>}
                    />
                    <CompactListItem
                      primary={<>Gestione <Bold>linea automatica e tracciabilità</Bold></>}
                    />
                  </List>
                </>
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ProjectCard
              title="Magazzini Ind. 4.0"
              text="Magazzini automatici e gestione magazzini statici."
              dialogContent={
                <>
                  <Typography>
                    <Bold>Kaire</Bold> collabora con i <Bold>costruttori di magazzini automatici</Bold> fornendo un pacchetto completo che parte dalla realizzazione progettuale della parte elettrica, per passare alla realizzazione dei quadri e bordo macchina.
                    <br/>
                    <br/>
                    Nella fornitura viene aggiunto il <Bold>software</Bold> di livello 00 per la movimentazione delle UDC a basso livello tramite logica PLC, e la gestione WMS delle locazioni magazzino interfacciandosi con i sistemi gestionali del cliente. <Bold>Collaudo e assistenza post collaudo sono il valore aggiunto che Kaire offre</Bold> sia al costruttore che al cliente finale.
                  </Typography>
                  <Box mt={4} mb={1}>
                    <AnimatedDivider in maxWidth="100%" width="100%"/>
                  </Box>
                  <Slider
                    elementsShown={1}
                    width={smallScreen ? 150 : 400}
                    height={smallScreen ? 150 : 300}
                  >
                    <SliderImageItem alt="WMS Interface SW PLC"       src="/images/wms.webp"/>
                    <SliderImageItem alt="Controllo TrasloElevatore"  src="/images/traslo1.webp"/>
                    <SliderImageItem alt="Controllo TrasloElevatore"  src="/images/traslo2.webp"/>
                  </Slider>
                </>
              }
            />
          </Grid>
        </Grid>
        <Card
          sx={{
            marginTop: 4,
          }}
        >
          <CardActionArea 
            sx={{ padding: 4 }}
            href="/k-trend"
            onClick={e => PageController.push('/k-trend', e)}  
          >
            <Paragraph
              subtitle="K-Trend"
              text={<>
                <strong>K-Trend</strong> è una applicazione per la storicizzazione e visualizzazione di dati dalle macchine/impianti di processo nel tempo.
              </>}
            />
          </CardActionArea>
          <CardActions 
            sx={{ 
              padding: 2, 
              justifyContent: 'flex-end' 
            }}
          >
            <Button 
              color="primary"
              variant="contained"
              href="/k-trend"
              onClick={e => PageController.push('/k-trend', e)} 
            >
              Scopri di più
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Box>

  )
}