import { Grid, List } from "@mui/material";
import Paragraph from "components/Paragraph";

import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import InsertChartRoundedIcon from '@mui/icons-material/InsertChartRounded';
import ShowChartRoundedIcon from '@mui/icons-material/ShowChartRounded';
import BoltRoundedIcon from '@mui/icons-material/BoltRounded';
import DesktopWindowsRoundedIcon from '@mui/icons-material/DesktopWindowsRounded';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';

import React from "react";
import ServicesListItem from "../components/ServicesListItem";

export default function OurServices(){
  return (
    <Paragraph
      title="Cosa facciamo?"
      subtitle="Sistemi software per l’integrazione verticale dell’intero processo produttivo:"
      hideDots
      showDivider
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
      >
        <Grid item>
          <List>
            <ServicesListItem
              icon={<ArrowForwardRoundedIcon/>}
              text="Tracciabilità prodotto"
            />
            <ServicesListItem
              icon={<InsertChartRoundedIcon/>}
              text="Analisi performance"
            />
            <ServicesListItem
              icon={<ShowChartRoundedIcon/>}
              text="Controllo produzione"
            />
          </List>
        </Grid>
        <Grid item>
          <List>
            <ServicesListItem
              icon={<BoltRoundedIcon/>}
              text="Gestione delle Energie (elettricità, gas e acqua)"
            />
            <ServicesListItem
              icon={<DesktopWindowsRoundedIcon/>}
              text="Sviluppo in ambiente windows"
            />
            <ServicesListItem
              icon={<StorageRoundedIcon/>}
              text="Gestione e progettazione dei principali database"
            />
          </List>
        </Grid>
      </Grid>
    </Paragraph>
  )
}