import { Typography } from "@mui/material";
import Bold from "components/Bold";
import CopyableTypography from "components/CopyableTypography";
import React from "react";

export default function ContactBox(props){
  return (
    <>
      <Typography variant="subtitle1">
        <Bold>
          {props.name}
        </Bold>
      </Typography>
      <CopyableTypography
        text={props.contact}
        snackbarmessage={props.snackbarmessage}
      />
    </>
  )
}
