import { AccordionSummary, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

export default function AccordionSummaryWithIcon(props){
  return (
    <AccordionSummary
      expandIcon={<ExpandMoreRoundedIcon/>}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
      >
        <Box mr={2}>
          {props.icon}
        </Box>
        <Typography>
          {props.children}
        </Typography>
      </Grid>
    </AccordionSummary>
  )
}