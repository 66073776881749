import React, { useEffect } from "react"

export default function useOnScreen(ref, options) {
  const {oneTime} = options;
  const [isIntersecting, setIntersecting] = React.useState(false)
  const isIntersectingRef = React.useRef(isIntersecting)

  const observer = new IntersectionObserver(([entry]) => {
      if(oneTime && isIntersectingRef.current)
        return;

      isIntersectingRef.current = entry.isIntersecting
      setIntersecting(entry.isIntersecting)
    }
  )

  useEffect(() => {
    observer.observe(ref.current)
    return () => { observer.disconnect() }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isIntersecting
}