import { Button, Divider, Grid, Tooltip, Typography, useMediaQuery } from "@mui/material";
import React from "react";

import { Box, darken } from "@mui/system";
import { ThemeContext } from "theme/ThemeContext";

import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import Logo from "./Logo";
import AnimatedDotsRow from "./AnimatedDotsRow";
import TypographyWithIcon from "./TypographyWithIcon";
import MaybeShow from "./MaybeShow";
import PageController from "lib/PageController";
import CopyableTypography from "./CopyableTypography";
import ClickableTypography from "./ClickableTypography";

export default function Footer(){
  const {theme} = React.useContext(ThemeContext)
  const middleScreen = useMediaQuery(theme.breakpoints.down('md'));

  const getBackgroundColor = () => {
    if(theme.palette.mode === "light")
      return darken(theme.palette.background.default, 0.05)

    return darken(theme.palette.background.default, 0.5)
  }

  return (
    <Box
      sx={{
        backgroundColor: getBackgroundColor(),
        width: "100vw",
        padding: 4
      }}
    >
      <Grid
        container
        direction="row"
        sx={{
          maxWidth: 1200,
          width: "80%",
          margin: "auto"
        }}
      >
        <Grid 
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          md={8}
          spacing={2}
          sx={{
            "& > *": {
              textAlign: "center",
              justifyContent: middleScreen ? "center" : "flex-start"
            }
          }}
        >
          <TypographyWithIcon
            gridItem
            color="textSecondary" 
            icon={<BusinessRoundedIcon color="secondary"/>}
            TypograghyAlternative={
              <CopyableTypography
              text="Via Marcello Finzi, 587 41122 Modena MO Italia"
              snackbarmessage="Indirizzo copiato"
            /> 
            }
          />           
          <TypographyWithIcon
            gridItem
            color="textSecondary" 
            icon={<LocalPhoneRoundedIcon color="secondary"/>}
            TypograghyAlternative={
              <CopyableTypography
                text="059 450736"
                snackbarmessage="Numero di telefono copiato"
              /> 
            }
          />
          <TypographyWithIcon
            gridItem
            color="textSecondary" 
            icon={<EmailRoundedIcon color="secondary"/>}
            TypograghyAlternative={
              <CopyableTypography
                text="info@kaire-automation.it"
                snackbarmessage="Email copiata"
              />
            }
          />            
          <TypographyWithIcon
            gridItem
            color="textSecondary" 
            icon={<FolderRoundedIcon color="secondary"/>}
            TypograghyAlternative={
              <CopyableTypography
                text="kaireautomation@legalmail.it"
                snackbarmessage="Copiato"
              /> 
            }
          />  
          <TypographyWithIcon
            gridItem
            color="textSecondary" 
            icon={<FolderRoundedIcon color="secondary"/>}
            TypograghyAlternative={
              <Tooltip 
                arrow
                placement="bottom"
                title="Premi per aprire"
              >
                <ClickableTypography
                  onClick={e => {
                    window.open("/Contributi-pubblici.pdf", '_blank').focus()
                  }}
                >
                  Contributi pubblici
                </ClickableTypography>
              </Tooltip>
            }
          />            
        </Grid>
        <Grid 
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          md={4}
        >
          <Logo height={middleScreen ? 75 : 90}/>
          <MaybeShow show={!middleScreen}>
            <Box p={1}>
              <AnimatedDotsRow in/>
            </Box>
          </MaybeShow>
          <Button 
            variant="outlined"
            color="secondary"
            href="/contattaci"
            onClick={e => PageController.push("/contattaci", e)}
          >
            Contattaci
          </Button>
        </Grid>
      </Grid>
      <Box
        pt={4}
        pb={3}
      >
        <Divider/>
      </Box>
      <Typography variant="body2" align="center">
        Codice ateco 33.20.03 C.F. e P. Iva 03634570364 C.C.I.A.A. MO -404681
      </Typography>
    </Box>
  )
}