import React from 'react';
import { ThemeContext } from 'theme/ThemeContext';
import { Grow } from '@mui/material';
import { alpha, Box } from '@mui/system';

export default function GrowingDot(props){
  const {theme} = React.useContext(ThemeContext)

  return (
    <Grow {...props}>
      <Box 
        sx={{
          background: alpha(theme.palette.primary.main, 0.25),
          width: 12,
          height: 12,
          borderRadius: "50%",
          margin: 1,
        }}
      />
    </Grow>
  )
}