import React from "react";
import { ListItem, ListItemText, useMediaQuery } from "@mui/material";

import { alpha } from "@mui/system";

import { ThemeContext } from "theme/ThemeContext";
import PageController from "lib/PageController";

export default function DrawerListItem(props){
  const [isHovering, setIsHovering] = React.useState(false);
  const {theme} = React.useContext(ThemeContext)
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  return (
    <ListItem
      onClick={e => {
        props.onClick?.(e)
        
        if(props.href)
          PageController.push(props.href, e)
      }}
      href={props.href}
      onMouseEnter={_ => setIsHovering(true)}
      onMouseLeave={_ => setIsHovering(false)}
      button 
      component="a"
      sx={{
        borderRadius: "33px",
        margin: 2,
        marginLeft: 6,
        marginRight: 6,
      }}
    >
      <ListItemText 
        primary={props.text}
        primaryTypographyProps={{
          variant: smallScreen ? "h5" : "h4",
          sx: {
            transition: `${theme.transitions.duration.short}ms color`,
            color: isHovering 
              ? theme.palette.text.primary 
              : alpha(theme.palette.text.primary, 0.5)
          }
        }}
        sx={{
          transition: "0.25s all",
          transform: isHovering ? "translate(8px, 0px)" : "translate(0px, 0px)"
        }}
      />
    </ListItem>
  )
}