/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Box } from '@mui/system';
import { ThemeContext } from 'theme/ThemeContext';
import { useMediaQuery } from '@mui/material';
import useWindowDimensions from 'lib/hooks/useWindowDimension';


export default function Parallax({height, directions, onScroll, children}){
  const [isImgLoaded, setIsImgLoaded] = React.useState(false)
  const [screenIsUpdated, setScreenIsUpdated] = React.useState(false)

  const {windowWidth, windowHeight} = useWindowDimensions();

  const ref = React.useRef()
  const childRef = React.useRef()

  const {theme} = React.useContext(ThemeContext)
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const smallScreenRef = React.useRef(smallScreen)

  const heightRef = React.useRef(height)

  useEffect(() => {
    if(!ref.current)
      return;

    updateOnScroll()
    document.addEventListener("scroll", updateOnScroll)

    return () => document.removeEventListener("scroll", updateOnScroll)
  }, [ref.current])

  useEffect(() => {
    updateOnScroll()
  }, [isImgLoaded, windowWidth, windowHeight])

  useEffect(() => {
    setScreenIsUpdated(!screenIsUpdated)
    smallScreenRef.current = smallScreen
  }, [smallScreen])

  useEffect(() => {
    heightRef.current = height
  }, [height])

  const updateOnScroll = () => {
    if(!ref.current)
      return;

    const midScreen = window.scrollY + windowHeight/2 - getHeight()/2

    const boxImgPosY = ref.current.offsetTop
    const startBoxImgPosY = boxImgPosY + getHeight()
    const endBoxImgPosY = boxImgPosY - windowHeight
    const range = (startBoxImgPosY - endBoxImgPosY) / 2

    const normalizedOffset = (boxImgPosY - midScreen) / range

    onScroll?.(normalizedOffset);
    updateDirections(normalizedOffset);
  }

  const updateDirections = (offset) => {
    Object.keys(directions).forEach(direction => {
      updateDirection(direction, offset)
    })
  }

  const updateDirection = (direction, newOffset) => {
    if(newOffset > 1 || newOffset < -1)
      return;
    
    const excursion = getExcursion(directions[direction].excursion)
    const middlePoint = getMiddlePoint()
    const startingPoint = excursion > 0 ? -middlePoint : middlePoint
    const inRangeOffset = startingPoint + newOffset * excursion

    childRef.current.style[direction] = inRangeOffset + "px"
  }

  const getMaxExcursion = () => getHeight()/10;

  const getHeight = () => {
    if(heightRef.current !== "auto")
      return heightRef.current

    if(smallScreenRef.current)
      return 200
    
    return 300
  }

  const getMiddlePoint = () => {
    return childRef.current.offsetHeight/2 - getHeight()/2
  }

  const getExcursion = (excursion) => {
    if(excursion !== "auto")
      return excursion

    return getMaxExcursion()
  }


  const childWithRef = React.Children.map(children, child => {
    return React.cloneElement(child, {
      ref: childRef, 
      onLoad: _ => setIsImgLoaded(true)
    })
  })

  return (
    <Box
      ref={ref}
      sx={{
        height: getHeight(),
        position: "relative",
        overflow: "hidden",
        "& img": {
          width: "100%",
          position: "absolute",
          zIndex: -1,
          minHeight: getHeight() + getMaxExcursion()*2,
        }
      }}
    >
      {childWithRef}
    </Box>
  )
}

Parallax.defaultProps = {
  height: "auto",
  directions: {"top": {excursion: "auto"}}
}