import { Box } from "@mui/system";

import Parallax from "components/Parallax";
import React from "react";
import Partners from "./sections/Partners";
import ServicesAfterSales from "./sections/ServicesAfterSales";
import Hardware from "./sections/Hardware";
import Software from "./sections/Software";
import OurServices from "./sections/OurServices";

export default function Services(){
  return (
    <Box
      mt={12}
      minHeight="100vh"
    >
      <OurServices/>

      <Parallax>
        <img src="images/DSC_9819.webp" alt="kaire impianto"/>
      </Parallax>
      <Box>

        <Software/>
        <Box mt={4} mb={4}>
          <Hardware/>
        </Box>
        <Box mt={4} mb={4}>
          <ServicesAfterSales/>
        </Box>

        <Parallax>
          <img src="images/quadri.webp" alt="kaire metal logo"/>
        </Parallax>
        
        <Partners/>
      </Box>
    </Box>
  )
}