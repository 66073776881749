import { Snackbar, Tooltip } from "@mui/material";
import React from "react";
import ClickableTypography from "./ClickableTypography";

export default function CopyableTypography({text, snackbarmessage, ...props}){
  const [openSnackBar, setOpenSnackBar] = React.useState(false);

  return (
    <>
      <Tooltip
        arrow
        title="Premi per copiare"
        placement="bottom"
      >
        <ClickableTypography
          {...props}
          onClick={_ => {
            setOpenSnackBar(true)
            navigator.clipboard.writeText(text);
          }}
        >
          {text}          
        </ClickableTypography>
      </Tooltip>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        open={openSnackBar}
        onClose={_ => setOpenSnackBar(false)}
        message={snackbarmessage}
      />
    </>
  )
}