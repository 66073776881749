import React from 'react';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';


export default function VideoContainer(props){
  const {
    children,
    height,
    video
  } = props

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          width: "100vw",
          height: height,
          backdropFilter: "blur(15px)"
        }}
      />
      <video 
        muted
        loop
        autoPlay
        style={{
          width: "100vw",
          height: height,
          objectFit: "cover"
        }}
      >            
          <source src={video} type="video/webm"/>
          Your browser does not support the video tag.
      </video>  
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          height: height,
          position: "absolute",
          top: 0
        }}
      >
        {children}
      </Grid>
    </>
  )
}