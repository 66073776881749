import { Typography } from "@mui/material";
import React from "react";

const ClickableTypography = React.forwardRef(({children, ...props}, ref) => {
  return (
    <Typography 
      ref={ref}
      {...props}
      variant="subtitle1"
      sx={{
        "&:hover": {
          cursor: "pointer",
          textDecoration: "underline"
        }
      }}
    >
      {children}
    </Typography>
  )
})

export default ClickableTypography;