import { alpha, keyframes } from "@mui/system";
import { Divider } from '@mui/material';
import React from "react";
import { ThemeContext } from "theme/ThemeContext";


const AnimatedDivider = React.forwardRef((props, ref) => {
  const {theme} = React.useContext(ThemeContext)

  const {
    animationDuration,
    width,
    maxWidth,
    startAnimation,
  } = props

  const animate = keyframes`
    from {max-width: 0px}
    to {max-width: ${maxWidth};}
  `

  return (
    <Divider
      ref={ref}
      sx={{
        animation: `${animate} ${animationDuration} ease-in-out`,
        animationPlayState: startAnimation ? "running" : "paused",
        backgroundColor: alpha(theme.palette.primary.main, 0.25),
        width: width,
        maxWidth: maxWidth,
        margin: "0 auto"
      }}    
    />
  )
})

AnimatedDivider.defaultProps = {
  width: "75%",
  maxWidth: "900px",
  startAnimation: true,
}

export default AnimatedDivider