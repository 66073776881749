import React from 'react';

import Drawer from '@mui/material/Drawer';
import { Backdrop, List } from '@mui/material';
import { alpha } from '@mui/system';
import { ThemeContext } from 'theme/ThemeContext';

export default function CustomDrawer(props) {
  const {theme} = React.useContext(ThemeContext)

  return (
    <>
      <Backdrop
        open
        onClick={props.onClose}
        sx={{
          zIndex: theme.zIndex.drawer - 1,
          left: props.open ? "0%" : "100%",
          transition: `${theme.transitions.duration.complex}ms left ease-out !important`,
          background: alpha(theme.palette.secondary.main, 0.2),
          backdropFilter: "blur(10px)",
        }}
      />
      <Drawer
        anchor={"right"}
        open={props.open}
        onClose={props.onClose}
        PaperProps={{sx: {marginTop: "72px"}}}
        BackdropProps={{sx: {backgroundColor: "transparent"}}}
      >
        <List>
          {props.children}
        </List>
      </Drawer>
    </>
  );
}