import Layout from 'components/Layout';
import Contacts from 'pages/Contacts/Contacts';
import Home from 'pages/Home';
import KTrend from 'pages/K-Trend/KTrend';
import Projects from 'pages/Projects/Projects';
import Services from 'pages/Services/Services';
import WhoWeAre from 'pages/WhoWeAre/WhoWeAre';
import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import history from './history';


function App() {
  return (
    <Layout>
      <Router history={history}>
        <Switch>
          <Route path="/" exact component={Home}/>
          <Route path="/chi_siamo" exact component={WhoWeAre}/>
          <Route path="/servizi" exact component={Services}/>
          <Route path="/progetti" exact component={Projects}/>
          <Route path="/contattaci" exact component={Contacts}/>
          <Route path="/k-trend" exact component={KTrend}/>
        </Switch>
      </Router>
    </Layout>
  );
}

export default App;
