import { CssBaseline, useMediaQuery } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React, { createContext, useEffect } from 'react';
import useThemeModeStorage from '../lib/hooks/useThemeModeStorage';
import getTheme from './Theme';

export const ThemeContext = createContext({
  themeMode: null,
  toggleThemeMode: _ => {}
});

export function ThemeContextComponent({children}) {
  const themeModeStorage = useThemeModeStorage()
  const browserThemeMode = useMediaQuery('(prefers-color-scheme: dark)') 
    ? "dark" 
    : "light";

  const getThemeMode = () => {
    const storageThemeMode = themeModeStorage.get()

    if(!storageThemeMode)
      return browserThemeMode;

    return storageThemeMode
  }

  const toggleThemeMode = () => {
    const newThemeMode = themeMode === "dark" 
      ? "light" 
      : "dark"

    document.body.style.transition = `${theme.transitions.duration.complex}ms background-color, color`  
    setThemeMode(newThemeMode)
  }

  const [themeMode, setThemeMode] = React.useState(getThemeMode())

  useEffect(() => {
    themeModeStorage.set(themeMode)
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const theme = getTheme(themeMode)
  const contextValues = {
    theme,
    themeMode,
    toggleThemeMode,
  }

  return (
    <ThemeContext.Provider value={contextValues}>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}