import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from "@mui/material";
import React from "react";
import { ThemeContext } from "theme/ThemeContext";

export default function DialogBase(props) {
  const {theme} = React.useContext(ThemeContext)
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };


  return (
    <Dialog 
      onClose={handleClose} 
      open={open}
      maxWidth={props.size}
      fullScreen={smallScreen}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        {props.children}
      </DialogContent>
      <DialogActions
        sx={{
          background: theme.palette.background.default
        }}
      >
        <Button onClick={handleClose}>Chiudi</Button>
      </DialogActions>
    </Dialog>
  );
}

DialogBase.defaultProps = {
  size: "md"
}