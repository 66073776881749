import Paragraph from "components/Paragraph";

import Bold from "components/Bold";
import React from "react";

export default function ServicesAfterSales(){
  return (
    <Paragraph
      subtitle="Servizio Post vendita & assistenza"
      hideDots
      text={
        <>
          La clientela <Bold>Kaire</Bold> può contare su un partner sempre presente per ogni tipo di assistenza in caso di fermi o malfunzionamenti.
        </>
      }
    />
  )
}