const useThemeModeStorage = () => {
  const localStorageThemeKey = "kaire-website-theme-mode"

  const set = (themeMode) => {
    window
      .localStorage
      .setItem(localStorageThemeKey, themeMode);
  }

  const get = () => {
    return window
      .localStorage
      .getItem(localStorageThemeKey)
  }

  return {
    get,
    set
  }
}

export default useThemeModeStorage