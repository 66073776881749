import { Accordion, AccordionDetails, List } from "@mui/material";
import { Box } from "@mui/system";
import Paragraph from "components/Paragraph";

import KeyboardAltRoundedIcon from '@mui/icons-material/KeyboardAltRounded';
import DesignServicesRoundedIcon from '@mui/icons-material/DesignServicesRounded';
import IntegrationInstructionsRoundedIcon from '@mui/icons-material/IntegrationInstructionsRounded';

import AccordionSummaryWithIcon from "components/AccordionSummaryWithIcon";
import Bold from "components/Bold";
import React from "react";
import CompactListItem from "../../../components/CompactListItem";

export default function Software(){
  return (
    <Paragraph
      subtitle="Progettazione Software"
      hideDots
    >
      <Box mt={2}>
        <Accordion>
          <AccordionSummaryWithIcon
            icon={<KeyboardAltRoundedIcon/>}
          >
            Progettazione <Bold>Software PLC</Bold>
          </AccordionSummaryWithIcon>
          <AccordionDetails>
            <List>
              <CompactListItem
                primary="Siemens S5/S7/TIA PORTAL"
              />
              <CompactListItem
                primary="Siemens TI"
              />
              <CompactListItem
                primary="Omron"
              />
              <CompactListItem
                primary="Allen Bradley"
              />
              <CompactListItem
                primary="Conversione/Revamping da vecchi sistemi di automazione a nuove tecnologie"
              />
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummaryWithIcon
            icon={<DesignServicesRoundedIcon/>}
          >
            Progettazione Software <Bold>HMI/SCADA</Bold>
          </AccordionSummaryWithIcon>
          <AccordionDetails>
            <CompactListItem
              primary="Wonderare Intouch"
            />
            <CompactListItem
              primary="Progea Movicon"
            />
            <CompactListItem
              primary="WinCC Professional"
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummaryWithIcon
            icon={<IntegrationInstructionsRoundedIcon/>}
          >
            Progettazione <Bold>sitemi integrati</Bold>
          </AccordionSummaryWithIcon>
          <AccordionDetails>
            <CompactListItem
              primary="Studio analisi per connettività studio studio Industria 4.0"
            />
            <CompactListItem
              primary="Assistenza remota"
            />
            <CompactListItem
              primary="Reperibilità H24"
            />
          </AccordionDetails>
        </Accordion>
      </Box>
    </Paragraph>
  )
}