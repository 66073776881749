import { ListItem, ListItemText } from "@mui/material";

export default function CompactListItem({primary, secondary}){
  return (
    <ListItem
      sx={{
        background: "rgba(0, 0, 0, 0.1)",
        margin: "4px",
        width: "auto"
      }}
    >
      <ListItemText
        primary={primary}
        secondary={secondary}
      />
    </ListItem>
  )
}